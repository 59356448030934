import React, { useState, useEffect } from "react"
import { Title, Link } from "react-head";

import '../styles/main.scss';

import Logo from "../images/logo-8bit-horiz.svg";
import Plumber from "../images/plumber-8bit.svg";

// markup
const IndexPage = () => {
  const [poolStats, setPoolStats] = useState({});
  const [bonusPercent, setBonusPercent] = useState(10)

  useEffect(() => {  
    const fetchData = async () => {
      const response = await fetch('https://js.adapools.org/pools/209b1ad731564c614a72caebfaeb42c266bcd073133247304b334dc4/summary.json');
      const { data } = await response.json();
      
      setPoolStats( data );

      const totalStake = data.total_stake;
      const basePercent = 10;
      const bonusIncreaseInterval = 100000000000;
      const bonus = basePercent + Math.floor(totalStake / bonusIncreaseInterval);

      setBonusPercent(bonus > 50 ? 50 : bonus);
    }  
    
    fetchData();
  }, []);

  const loveToAda = (lovelace) => {
    return (lovelace / 1000000).toFixed(2);
  }

  return (
    <main>
      <Title>Fat Stakes - A staking pool with fat rewards</Title>
      <Link href="https://fonts.googleapis.com/css?family=Press+Start+2P" rel="stylesheet" />
      <div className="container">
        <div className="columns" style={ {marginTop: '1rem'} }>
          <div className="column">
            <img id="header-logo" src={Logo} alt="Fat Stakes" />
          
            <div style={{ display:'flex', alignItems:'center' }}>
              <span>Ticker:</span>
              <a href="https://adapools.org/pool/209b1ad731564c614a72caebfaeb42c266bcd073133247304b334dc4" className="nes-btn is-primary">
                <span className="is-primary">FAT</span>
              </a>
            </div>
          </div>
          <div className="column social-icons">
            <a href="https://twitter.com/Fat_Stakes" target="_blank" rel="noreferrer" aria-label="Twitter"><i className="nes-icon twitter is-medium"></i></a>
            <a href="https://github.com/fatstakes" target="_blank" rel="noreferrer" aria-label="GitHub"><i className="nes-icon github is-medium"></i></a>
          </div>
        </div>

        <br/>        
        <h1>A Cardano staking pool with fat rewards and bonuses.</h1> 
        
        <h2>Helping small delegators with less earn more ADA through bonus payouts.</h2>
        <h2>Let's become the fattest pool! <span className="nes-text is-primary">#GetFat</span></h2>
        
        
        {/* Ticker: <button type="button" class="nes-btn is-primary">FAT</button> */}

        <div className="nes-container with-title">
          <p className="title">Bonuses</p>
          <p>A portion of margin fees will always be dedicated to bonuses given back to delegators. A percentage of the margin earned will be sent to one random delegator on every payout as a bonus.</p>
          <p>For every 100k ADA stake added, the bonus will increase by 1%.</p>
        </div>

        <br/><br/>
        
        <div className="nes-container with-title">
          <p className="title">Pool Stats</p>
          <br/>
          <div className="columns">
            <div className="column">

              <div className="columns">
                <div className="column">
                  <p>Live Stake:</p>
                </div>
                <div className="column">
                  <p><span className="nes-text is-success is-bold">{loveToAda(poolStats.total_stake)} ADA</span></p>
                </div>
              </div>
              
              <div className="columns">
                <div className="column">
                  <p>Active Stake:</p>
                </div>
                <div className="column">
                  <p><span className="is-bold">{loveToAda(poolStats.active_stake)} ADA</span></p>
                </div>
              </div>
              
              <div className="columns">
                <div className="column">
                  <p>Saturation:</p>
                </div>
                <div className="column">
                  <p><span className="is-bold">{(poolStats.saturated * 100).toFixed(2)}% 👍</span></p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="columns">
                <div className="column">
                  <p><i className="nes-icon is-small heart"></i> Pledge:</p>
                </div>
                <div className="column">
                  <p><span className="is-bold">{loveToAda(poolStats.pledge)} ADA</span></p>
                </div>
              </div>
              
              <div className="columns">
                <div className="column">
                  <p>Fixed Cost:</p>
                </div>
                <div className="column">
                  <p><span className="is-bold">{loveToAda(poolStats.tax_fix)} ADA</span></p>
                </div>
              </div>
              
              <div className="columns">
                <div className="column">
                  <p>Margin*:</p>
                </div>
                <div className="column">
                  <p><span className="is-bold">{poolStats.tax_ratio * 100}%</span></p>
                </div>
              </div>
            </div>
          </div>

          <br/>
          <div className="nes-container with-title">
            <p className="title">Bonus</p>

            <div className="columns">
              <div className="column">
                <p>Current Bonus: <span className="nes-text is-primary is-bold">{bonusPercent}% of margin</span></p>
                <progress className="nes-progress is-primary" value={bonusPercent} max="50"></progress>
                <p>Next Bonus Increase: <span className="nes-text is-bold">100,000 ADA Total Stake</span></p>
              </div>
            </div>
          </div>
        </div>
        
        <br/>
        <p>*A portion of the margin is used towards bonus rewards paid back to delegators, up to 50%.</p>


        <img id="plumber" src={Plumber} alt="Fat Stakes plumber in 8-bit" />
        <br/><br/>

        <div style={{textAlign: 'center'}}>
          © Fat Stakes / <a href="https://t.me/fatstakespool" target="_blank" rel="noreferrer">Telegram</a>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
